import React from "reactn";
import { LiveView1, LiveView2, LiveView3, LiveView5 } from "../../assets";
import "../../assets/css/componentSpecificCss/cssforProgram.css";
import { format, addWeeks, addDays, subDays } from "date-fns";
import Checkout from "./checkout";
import axios from "axios";
import SignIn from "../../components/modals/SignInModal";
import SignUpNewModal from "../../components/modals/SignUpNewModal";
import CartModal from "../../components/parents/CartModal";
import { OrangePin } from "../../assets";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import {
  constructTimeRangeFromDecimalHours,
  getFormattedTimeRange,
  getGroupedWeekdayRange,
  testIsInteger
} from "./SpecificPageUtils/utils";
import { ReactBnbGallery } from "react-bnb-gallery";
import NoDataAvailable from "../../components/front/partner/NoDataAvailable";
import Radio from "@material-ui/core/Radio";
import SummarySubTotal from "./SpecificPageUtils/SummarySubTotal";
import ShareButtonModal from "../../components/modals/ShareButtonModal";
import qs from "qs";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  console.log("daysOfWeek...", daysOfWeek);
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

/**
 * Returns lastDay + 1week - 1day, and then iterate backwards through the days until it hits one of the daysOfWeek.
 * @param {Date} lastDay
 * @param {*} daysOfWeek
 * @returns {Date}
 */
const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};

class SpecificOnline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textShown: false,
      mapShown: false,
      gallery: [],
      program: {
        age_range: [],
        timing: [0, 0],
        plans: [],
        age_groupings: [],
        days_of_week: {},
        program_start: new Date(),
        number_of_weeks: 0,
        other_locations: []
      },
      selected: null,
      selectedPlan: {}, //as of 2022-10-23, this doesn't seem to be used anywhere after setting state in componentDidUpdate().
      checkout: false,
      isOpenSignUpModal: false,
      showDescription: true,
      showAdditionalDetails: true,
      isBookingFull: false,
      isEnableWaitlist: false,
      showCancellationPolicy: true,
      singleSessionQuantity: [],
      orgSlug: "",
      singleSessionSelectedDates: [],
      isPlanFull: false,
      email: "",
      checkoutType: "Regular",
      showCart: false,
      disableAddToCart: false,
      isInterested: false
    };
  }

  componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.selected !== this.state.selected) {
      const selectedPlanJSON = this.state.program?.plans?.find(
        e => JSON.parse(e).id === this.state.selected
      );
      console.assert(
        !selectedPlanJSON,
        `No plan found with id=${this.state.selected}`
      );
      if (!selectedPlanJSON) return;

      const selectedPlan = JSON.parse(selectedPlanJSON);
      console.log("selectedPlan...", selectedPlan);
      this.setState({
        selectedPlan
      });
    }
  }

  async componentDidMount() {
    console.assert(
      testIsInteger(this.props.match.params.id),
      "Invalid program Id specified: " + this.props.match.params.id
    );

    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/search/virtual/${this.props.match.params.id}`;
    const results = await axios.get(ep);
    console.log("results", results);
    if (this.global.dsUser) {
      const ep2 = `${process.env.REACT_APP_API}/par/enrollment/get-interested`;
      const results2 = await axios.get(ep2);

      const myInterestedArr = results2?.data?.data?.filter(program => {
        return program.programId == this.props.match.params.id;
      });

      const isSavedAsInterested =
        myInterestedArr.length > 0 && !myInterestedArr[0].archived
          ? true
          : false;

      this.setState({
        isInterested: isSavedAsInterested
      });
    }

    if (!results.data.success || results.data.data.isArchived) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }

    let gallery;
    if (this.props.match.params.slug) {
      const gallery_ep = `${process.env.REACT_APP_API}/partners/search/profile/${this.props.match.params.slug}`;
      gallery = await axios.get(gallery_ep);
    }

    this.setState({
      customers: { Enrollments: results.data.data.enrollments }
    });

    if (!results.data.success) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }
    this.setState({ orgSlug: results.data.data.slug });
    if (results.data.success && results.data.data.isArchived) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }

    //I'm not entirely sure which is better practice: using props.location or props.history.location.
    //So, I'm just going to access both and do some qs.parsing as a last resort.
    const queryParams = this.props.location.search
      ? this.props.location.query ||
      this.props.history.location.query ||
      qs.parse(this.props.location.search.substr(1))
      : {};

    if (queryParams && queryParams.plan) {
      this.setState({
        selected: parseInt(queryParams.plan),
        installments: queryParams.modifier === "Installments" || queryParams.modifier === "I",
        dropIns: queryParams.modifier === "Drop-In" || queryParams.modifier === "D",
        checkout: true,
        startDate: queryParams.startDate
          ? new Date(queryParams.startDate)
          : null,
        recurringCustomerSelectedDays: queryParams.recurringCustomerSelectedDays
          ? JSON.parse(queryParams.recurringCustomerSelectedDays)
          : null,
        isSkipForm: queryParams.skipForm === "true",
        isWaiveDeposit: queryParams.waiveDeposit === "true",
        isStartSet: queryParams.isStartSet === "true"
      });
    }

    if (queryParams && queryParams.visible && queryParams.visible === "true") {
      this.setGlobal({
        footer: false
      })
    }

    console.log(
      "parterSpecificOnline.jsx 200: this.props.location.query",
      queryParams
    );

    /*** COMMENTING OUT THIS SEEMINGLY REDUNDANT SECTION! DELETE IN A FEW MONTHS TIME!
 *** It's just mostly a repetition of the chunk a few lines above,
 *** but using "this.props.history.location" instead of "this.props.location"
 *** I tested it: this.props.history.location === this.props.location   //is true!

    if (!this.props.location.query) {
      this.setState({
        startDate: this.props.history.location.query.startDate
          ? new Date(this.props.history.location.query.startDate)
          : null,
        recurringCustomerSelectedDays: this.props.history.location.query
          .recurringCustomerSelectedDays
          ? JSON.parse(
              this.props.history.location.query.recurringCustomerSelectedDays
            )
          : null,
        isSkipForm: this.props.history.location.query.isSkipForm == "true",
        isWaiveDeposit:
          this.props.history.location.query.isWaiveDeposit == "true",
        isStartSet: this.props.history.location.query.isStartSet == "true"
      });
    }
***/

    //this is only for single session invites
    const path = `${process.env.REACT_APP_API}${this.props.location.pathname}${this.props.location.search}`;
    const params = new URL(path).searchParams;
    if (params.get("singleSessionQuantity")) {
      this.setState({
        singleSessionQuantity: [parseInt(params.get("singleSessionQuantity"))]
      });
    }
    if (params.get("singleSessionSelectedDates")) {
      this.setState({
        singleSessionSelectedDates: JSON.parse(
          params.get("singleSessionSelectedDates")
        )
      });
    }

    //BELOW IS COMMENTED OUT! DELETE LATER! Unsure why this needs to be called twice in succession.
    //this.fetchEnrollmentCount(results.data.data.id);

    const isCountLargerThanSpots = results.data.data.unlimited_spots
      ? false
      : !!(
        results.data.data.total_spots &&
        (await this.fetchEnrollmentCount(results.data.data.id)) >=
        results.data.data.total_spots
      );

    // let alreadyEnrolled = false;
    // if (this.global.dsUser) {
    //   const allEnrollments = await this.fetchUserEnrollments();
    //   alreadyEnrolled = allEnrollments.filter(user =>
    //     user.dsUserId === this.global.dsUser.id &&
    //     user.programId === results.data.data.id &&
    //     user.status_stage !== "Cancelled"
    //   ).length > 0;
    // }

    const isEnableWaitlist = !!(
      results.data.data.enable_waitlist && isCountLargerThanSpots
    );

    const isBookingFull = isCountLargerThanSpots && !isEnableWaitlist;

    this.setState({
      program: results.data.data,
      isBookingFull: isBookingFull,
      isEnableWaitlist: isEnableWaitlist,
      gallery: gallery.data?.data?.data?.gallery ? gallery.data?.data?.data?.gallery : []
    });

    if (this.props.location.query && this.props.location.query.plan) {
      this.setState({
        selected: parseInt(this.props.location.query.plan),
        isSkipForm: this.props.location.query.isSkipForm == "true"
      });
    }

    // if (
    //   this.props.location.search &&
    //   this.props.location.search.includes("visible=true")
    // ) {
    //   const planNumber = parseInt(
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   console.log(
    //     "plan number",
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   this.setState({ selected: planNumber });
    // }

    this.setGlobal({ loading: false });
  }

  fetchEnrollmentCount = async programId => {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/enrollments/count/${programId}`;
    const result = await axios.get(ep);

    this.setState({
      enrollmentCount: parseInt(result.data.data.count)
    });

    return parseInt(result.data.data.count);
  };

  fetchUserEnrollments = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/virtual/par/customers/all/${this.global.dsUser.id}`;
    const result = await axios.get(ep);
    return result.data.data;
  }

  getLocation(locations) {
    const data = locations.filter(
      e => e.address === this.state.program.address1
    );
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={this.state.program.main_lat}
        lng={this.state.program.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  }

  openGallery = () => {
    this.setState({
      galleryShown: !this.state.galleryShown
    });
  };

  dateIsValid = dateStr => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateStr.match(regex) === null) {
      return false;
    }

    const date = new Date(dateStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
      return false;
    }

    return date.toISOString().startsWith(dateStr);
  };

  saveInterestedProgram = async () => {
    const ep = `${process.env.REACT_APP_API}/par/enrollment/save-interested-program`;
    const results = await axios.post(ep, {
      program: this.state.program,
      type: "Online"
    });

    if (results.data.success) {
      if (results?.data?.data?.archived) {
        toast.success("Removed as interested program");
      } else {
        toast.success("Saved as interested program!");
      }
      this.setState({ isInterested: !this.state.isInterested });
    }
  };

  render() {
    //=================================================================================================
    const failedLocalStorageDsUserToken = () => {
      try {
        if (localStorage.getItem("ds_token")) {
          return false;
        }
        return false;
      } catch {
        console.log("FAILED TO FETCH FROM LOCAL STORAGE");
        return true;
      }
    };

    const isPlanSelected = () =>
      this.state.selected !== null && this.state.selected !== undefined; //do not use -1 to represent a non-selection. And id=0 is a valid price plan!

    const getAccountType = user => user.accountType;
    const isUserParent = user => getAccountType(user) === "Parent";
    const isUserVolunteer = user => getAccountType(user) === "Volunteer";
    const isUserStudent = user => getAccountType(user) === "Student";
    const canUserVisitCheckout = user =>
      isUserParent(user) || isUserVolunteer(user) || isUserStudent(user);
    const checkUserAndLoggedInAccountType = () =>
      this.global.dsUser && canUserVisitCheckout(this.global.dsUser);

    //===========================================================================
    // click handler for Enroll Now button
    //===========================================================================
    const onEnrollNow = async () => {
      if (!isPlanSelected()) {
        return toast.error(`Please select a plan!`);
      }
      const planId = this.state.selected;

      if (failedLocalStorageDsUserToken()) {
        console.log(
          "onEnrollNow: Failed to access local storage. Invoking window.open()."
        );
        window.open(
          `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/virtual/${this.state.program.id}?plan=${planId}`
        );
        return;
      }

      this.setState({ checkoutType: "Regular" });

      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
        if (checkUserAndLoggedInAccountType()) {
          return this.setState({ checkout: true });
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
      if (!localStorage.getItem("ds_token")) {
        return this.setState({ isOpenSignUpModal: true });
      } else {
        if (checkUserAndLoggedInAccountType()) {
          return this.setState({ checkout: true });
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
    };

    //===========================================================================
    // click handler for Add to Cart button
    //===========================================================================
    const onAddToCart = async () => {
      if (!isPlanSelected()) {
        return toast.error(`Please select a plan!`);
      }
      const planId = this.state.selected;

      if (failedLocalStorageDsUserToken()) {
        console.log(
          "onAddToCart: Failed to access local storage. Invoking window.open()."
        );
        window.open(
          `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/virtual/${this.state.program.id}?plan=${planId}`
        );
        return;
      }

      this.setState({ checkoutType: "Cart" });

      const data = {
        partnerId: this.state.program.partnerId,
        productId: this.state.program.id,
        planId: planId,
        quantity: this.state.singleSessionQuantity[planId]
          ? this.state.singleSessionQuantity[planId]
          : 1,
        productTable: "partner_online"
      };

      const addToCart = async () => {
        await this.dispatch.getCart({
          partnerSlug: this.props.match.params.slug
        });
        const ep = `${process.env.REACT_APP_API}/par/cart`;
        const res = await axios.put(ep, data);
        if (res.data.success) {
          toast.success("Added to cart!");
          this.setState({ showCart: true });
          console.log("this.global.cart:", this.global.cart);
        } else {
          toast.error(res.data.message);
        }
      };

      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
        if (checkUserAndLoggedInAccountType()) {
          await addToCart();
          return;
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
      if (!localStorage.getItem("ds_token")) {
        return this.setState({ isOpenSignUpModal: true });
      } else {
        if (checkUserAndLoggedInAccountType()) {
          await addToCart();
          return;
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
    };
    //=================================================================================================

    console.log(
      "failedLocalStorageDsUserToken",
      failedLocalStorageDsUserToken()
    );

    console.log("orgSlug", this.state.orgSlug);
    console.log("slug", this.props.match.params.slug);
    if (this.state.orgSlug !== this.props.match.params.slug) {
      return null;
    }

    /* No Timezones needed for this! No timezones are displayed for Online Virtual products. */
    let timing = this.state.program.timing;
    let timezone_offset =
      this.state.program.timezone_offset ||
      0; /*@@DEPRECATED@@: Will remove timezone_offset column in the future!*/
    let formattedTimeRange = "--";
    if (
      timing &&
      timing.length === 2 &&
      timing[0] !== "-1" &&
      timing[1] !== "-1"
    ) {
      let { startTime, endTime } = constructTimeRangeFromDecimalHours(
        parseFloat(timing[0]) -
        timezone_offset /*@@DEPRECATED@@: Will remove timezone_offset column in the future!*/,
        parseFloat(timing[1]) -
        timezone_offset /*@@DEPRECATED@@: Will remove timezone_offset column in the future!*/,
        null
      );
      formattedTimeRange = getFormattedTimeRange(
        startTime,
        endTime,
        /*separator=*/ " - ",
        /*hideTZ=*/ true
      );
    }

    if (timing && timing.length > 2) {
      console.error(
        "partnerSpecificOnline was not meant to handle timing.length > 2"
      );
      formattedTimeRange = "<Error>";
    }

    if (timing[0] === "-1" || timing[1] === "-1") {
      formattedTimeRange = "<Error>";
    }

    let formattedOpenDays = getGroupedWeekdayRange(
      this.state.program.days_of_week
    );

    const queryParams = this.props.location.search
      ? this.props.location.query ||
      this.props.history.location.query ||
      qs.parse(this.props.location.search.substr(1))
      : {};
    //below, we're taking extra precautions for invites - because some may plans may be active=false.
    const onlyShowOnePlanEvenIfInactive =
      queryParams.visible && queryParams.visible !== "false";
    const urlPreSelectedPlan = testIsInteger(queryParams.plan)
      ? parseInt(queryParams.plan)
      : undefined;

    const singleDate = this.dateIsValid(
      this.state.program.program_start.toString()
    )
      ? new Date(this.state.program.program_start + "T00:00")
      : new Date(this.state.program.program_start);
    const formattedSingleDate = format(singleDate, "LL/dd/yy");

    const semesterStartDate =
      Object.keys(this.state.program.days_of_week).length !== 0
        ? getFirstDay(
          new Date(this.state.program.program_start),
          this.state.program.days_of_week
        )
        : null;
    const formattedSemesterStartDate =
      Object.keys(this.state.program.days_of_week).length !== 0
        ? format(semesterStartDate, "LL/dd/yy")
        : null;

    const semesterEndDate =
      Object.keys(this.state.program.days_of_week).length !== 0
        ? new Date(
          getLastDay(
            addWeeks(
              getFirstDay(
                new Date(this.state.program.program_start),
                this.state.program.days_of_week
              ),
              this.state.program.number_of_weeks - 1
            ),
            this.state.program.days_of_week
          )
        )
        : null;
    const formattedSemesterEndDate =
      Object.keys(this.state.program.days_of_week).length !== 0
        ? format(semesterEndDate, "LL/dd/yy")
        : null;

    const adjustNumWeeks = this.state.program.number_of_weeks === 0 ? 0 : 1;

    let planEndDate =
      Object.keys(this.state.program.days_of_week).length !== 0
        ? new Date(
          getLastDay(
            addWeeks(
              getFirstDay(
                new Date(this.state.program.program_start),
                this.state.program.days_of_week
              ),
              this.state.program.number_of_weeks - adjustNumWeeks
            ),
            this.state.program.days_of_week
          )
        )
        : null;

    let hours = 0;
    let minutes = 0;

    let endHours = 0;
    let endMinutes = 0;

    if (this.state.program.enableCustomTimes) {
      const options = { weekday: "long" };
      const dayOfWeek = planEndDate.toLocaleString("en-US", options);
      const times = this.state.program.customTimes[dayOfWeek]
        .filter((value, index) => value !== -1 && index % 2 == 0)
        .sort(function (a, b) {
          return parseFloat(a) - parseFloat(b);
        });
      const time = times.length === 0 ? 0 : parseFloat(times[0]); // decimal value representing hours and minutes
      const endTimes = this.state.program.customTimes[dayOfWeek]
        .filter((value, index) => value !== -1 && index % 2 == 1)
        .sort(function (a, b) {
          return parseFloat(a) - parseFloat(b);
        });
      const endTime = endTimes.length === 0 ? 24 : parseFloat(endTimes[0]);

      hours = Math.floor(time); // get the integer part (hours) using Math.floor()
      minutes = Math.round((time - hours) * 60); // get the decimal part (minutes) and convert to minutes
      endHours = Math.floor(endTime);
      endMinutes = Math.floor((endTime - endHours) * 60);
      console.log(
        `${hours} hours and ${minutes} minutes | ${endHours} hours and ${endMinutes} minutes`
      ); // logs "5 hours and 15 minutes"

      if (hours && hours !== -1) {
        planEndDate = new Date(planEndDate.setHours(hours));
        if (minutes) {
          planEndDate = new Date(planEndDate.setMinutes(minutes));
        }
      }

      if (this.state.program.continuous_registration) {
        if (endHours && endHours !== -1) {
          planEndDate = new Date(planEndDate.setHours(endHours));
          if (endMinutes) {
            planEndDate = new Date(planEndDate.setMinutes(endMinutes));
          }
        }
      }
    } else {
      const decimalValue = this.state.program.timing
        .filter((value, index) => value !== "-1" && index % 2 === 0)
        .sort(function (a, b) {
          return parseFloat(a) - parseFloat(b);
        });
      const time = decimalValue.length === 0 ? 0 : parseFloat(decimalValue[0]); // decimal value representing hours and minutes
      const endDecimalValue = this.state.program.timing
        .filter((value, index) => value !== "-1" && index % 2 === 1)
        .sort(function (a, b) {
          return parseFloat(a) - parseFloat(b);
        });
      const endTime =
        endDecimalValue.length === 0 ? 24 : parseFloat(endDecimalValue[0]);

      hours = Math.floor(time); // get the integer part (hours) using Math.floor()
      minutes = Math.round((time - hours) * 60); // get the decimal part (minutes) and convert to minutes
      endHours = Math.floor(endTime);
      endMinutes = Math.round((endTime - endHours) * 60);
      console.log(
        `${hours} hours and ${minutes} minutes | ${endHours} hours and ${endMinutes} minutes`
      ); // logs "5 hours and 15 minutes"

      if (hours && hours !== -1) {
        planEndDate = new Date(planEndDate.setHours(hours));
        if (minutes) {
          planEndDate = new Date(planEndDate.setMinutes(minutes));
        }
      }

      if (this.state.program.continuous_registration) {
        if (endHours && endHours !== -1) {
          planEndDate = new Date(planEndDate.setHours(endHours));
          if (endMinutes) {
            planEndDate = new Date(planEndDate.setMinutes(endMinutes));
          }
        }
      }
    }

    const dateString =
      typeof this.state.program.program_start === "string"
        ? this.state.program.program_start.substring(0, 10)
        : this.state.program.program_start;
    const date = new Date(dateString);
    const dateTime = DateTime.fromJSDate(date, {
      zone: this.state.program.timezone
    });
    const formattedDate = dateTime.toISODate();
    console.log("FORMATTED", dateString, date, dateTime, formattedDate);

    let startDate = null;
    if (typeof dateString === "string") {
      startDate = DateTime.fromISO(dateString, {
        zone: this.state.program.timezone
      }).toISO();
    }

    const dt = DateTime.fromISO(
      typeof dateString === "string" ? startDate : formattedDate,
      {
        zone: this.state.program.timezone
      }
    ).toISO();
    const programStart = DateTime.fromISO(dt, {
      zone: this.state.program.timezone
    }).plus({ minutes: minutes, hour: hours });
    const rezonedProgramStart = programStart.setZone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const programEnd = DateTime.fromISO(dt, {
      zone: this.state.program.timezone
    }).plus({
      minutes: endMinutes,
      hour: endHours,
      weeks: this.state.program.number_of_weeks - adjustNumWeeks
    });
    const rezonedProgramEnd = programEnd.setZone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const currTime = DateTime.local();

    console.log(
      "DATE TIMING",
      dt,
      "|||",
      new Date(programStart),
      "|||",
      new Date(rezonedProgramStart),
      "|||",
      new Date(rezonedProgramEnd),
      "|||",
      new Date(currTime)
    );

    console.log("end date", planEndDate);
    const isProgramTypeZero = parseInt(this.state.program.program_type) === 0; //program_type===0 is for Ongoing(recurring) and SingleDay(non-recurring).
    console.log(
      "parseInt(this.state.program.program_type) === 0",
      isProgramTypeZero
    );
    let noPlansAvailableMessage;

    const availablePricePlans = this.state.program.plans
      .sort((prev, next) => {
        let newPrev = prev;
        let newNext = next;
        if (typeof prev === "string") {
          newPrev = JSON.parse(prev);
        }

        if (typeof next === "string") {
          newNext = JSON.parse(next);
        }

        return newPrev.order - newNext.order;
      })
      //.sort((prev, next) => (prev.order < next.order ? -1 : 1))       //was previously sorted the same way twice. why twice? not sure.
      .filter(e => {
        const element =
          typeof e === "string" || e instanceof String ? JSON.parse(e) : e;

        if (onlyShowOnePlanEvenIfInactive) {
          return urlPreSelectedPlan === element.id;
        }

        const OVEndDate = this.state.program.continuous_registration
          ? new Date(rezonedProgramEnd) < new Date(currTime) &&
          !this.state.program.isRecurring
          : new Date(rezonedProgramStart) < new Date(currTime) &&
          !this.state.program.isRecurring;
        if (OVEndDate) {
          console.log("end date passed");
          if (this.state.program.continuous_registration) {
            noPlansAvailableMessage = "- End date has passed";
          } else {
            noPlansAvailableMessage = "- Start date has passed";
          }
          return false;
        }
        return true;
      });

    if (this.state.program?.published === false && (!this.props.location.query?.email || !this.props.location.query?.visible === "true")) {
      return <NoDataAvailable message="Program Unavailable" />
    }

    return this.state.checkout && this.global.dsUser ? (
      <Checkout
        form={this.state.program.formId}
        program={this.state.program}
        selected={this.state.selected}
        organization={this.state.program.organization_title}
        installments={this.state.installments}
        dropins={this.state.dropIns}
        close={_ => this.setState({ checkout: false })}
        type={"Online"}
        singleSessionQuantity={
          this.state.singleSessionQuantity[this.state.selected]
        }
        singleSessionSelectedDates={this.state.singleSessionSelectedDates}
        startDate={this.state.startDate}
        recurringCustomerSelectedDays={this.state.recurringCustomerSelectedDays}
        isSkipForm={this.state.isSkipForm}
        isWaiveDeposit={this.state.isWaiveDeposit}
        isStartSet={this.state.isStartSet}
        planWaitlist={this.state.isEnableWaitlist}
      />
    ) : (
      <div className="containerall" style={{ overflowX: "hidden" }}>
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={this.state.gallery.map(
            e => `${process.env.REACT_APP_DS_FILES_S3 + "/" + e}`
          )}
          onClose={this.openGallery}
        />
        {this.state.showCart && (
          <CartModal
            partnerSlug={this.props.match.params.slug}
            animation={true}
            hideCart={() => {
              this.setState({ showCart: false });
            }}
            toggleAddToCart={() => {
              this.setState({ disableAddToCart: !this.state.disableAddToCart });
            }}
          />
        )}
        {this.state.signIn && (
          <SignIn
            onClose={_ => this.setState({ signIn: false, email: "" })}
            program={this.state.program}
            checkout={_ => {
              if (this.state.checkoutType === "Cart") {
                this.setState({ signIn: false });
                onAddToCart();
              } else if (this.state.checkoutType === "Save") {
                this.setState({ signIn: false });
                this.saveInterestedProgram();
              } else {
                this.setState({ signIn: false, checkout: true });
              }
            }}
            openSignUpModal={_ => this.setState({ isOpenSignUpModal: true })}
            email={this.state.email}
          />
        )}

        {this.state.isOpenSignUpModal && (
          <SignUpNewModal
            onClose={_ => this.setState({ isOpenSignUpModal: false })}
            program={this.state.program}
            checkout={_ => {
              if (this.state.checkoutType === "Cart") {
                this.setState({ isOpenSignUpModal: false });
                onAddToCart();
              } else if (this.state.checkoutType === "Save") {
                this.setState({ isOpenSignUpModal: false });
                this.saveInterestedProgram();
              } else {
                this.setState({ isOpenSignUpModal: false, checkout: true });
              }
            }}
            openSignInModal={_ => this.setState({ signIn: true })}
            setEmail={enteredEmail => {
              this.setState({ email: enteredEmail });
            }}
          />
        )}
        {this.state.isOpenShareButtonModal && (
          <ShareButtonModal
            onClose={() => {
              this.setState({
                ...this.state,
                isOpenShareButtonModal: false
              })
              console.log("HERE", this.props.history.location.pathname)
              console.log((process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
                + this.props.history.location.pathname)
            }}
            url={(process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
              + this.props.history.location.pathname}
            // quote={"HELLO"}
            hashtag={"#DreamSchools"}
          // description={"DESCRIPTION STUFF"}
          />
        )}
        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">
              {this.state.program.program_name}{" "}
              {this.state.isEnableWaitlist && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    On Waitlist
                  </span>
                  {")"}
                </span>
              )}
              {this.state.isBookingFull && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    FULL
                  </span>
                  {")"}
                </span>
              )}
              {/* {(this.state.alreadyEnrolled && !this.state.program.allow_same_enrollment && !this.state.program.addChild &&
                      this.state.program.program_category !== "Single Sessions" && !this.state.program.application_required) && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    Already Enrolled
                  </span>
                  {")"}
                </span>
              )} */}
            </h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                style={{
                  margin: `0px 0px 10px 0px`,
                  color: `#999`,
                  fontFamily: `Montserrat`,
                  fontWeight: `600`,
                  textDecoration: `underline`,
                  cursor: "pointer",
                  fontSize: "1.2rem"
                }}
                onClick={_ => {
                  this.props.location.pathname.includes("iframe")
                    ? this.props.history.push(
                      `/iframes/programs/${this.props.match.params.slug}`
                    )
                    : this.props.history.push(
                      `/programs/${this.props.match.params.slug}`
                    );
                }}
              >
                {this.state.program.organization_title}
              </h4>
              <div>
                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                  onClick={this.openGallery}
                >
                  Gallery
                </button>

                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      isOpenShareButtonModal: true,
                    })
                  }}
                >
                  Share
                </button>

                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px" }}
                  onClick={async () => {
                    if (!this.global.dsUser) {
                      this.setState({
                        isOpenSignUpModal: true,
                        checkoutType: "Save"
                      });
                    } else {
                      this.saveInterestedProgram();
                    }
                  }}
                >
                  {this.state.isInterested ? "Unsave" : "Save"}
                </button>
              </div>
            </div>
          </div>
          <div className="maincontainerdiv">
            <img
              className="imageprogram"
              alt="program"
              src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.program.program_photo}`}
            />

            <div className="widgetdata">
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView1} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {formattedOpenDays}{" "}
                  {formattedTimeRange !== "<Error>"
                    ? `- ${formattedTimeRange}`
                    : ""}
                </p>
              </div>
              {!this.state.program.all_ages &&
                this.state.program.age_groupings.length === 0 ? null : (
                <div
                  className="box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px"
                  }}
                >
                  <img src={LiveView2} alt="icon" className="icon-img" />

                  <p className="icon-text">
                    {this.state.program.all_ages
                      ? "All Ages"
                      : this.state.program.age_groupings
                        .map(e => JSON.parse(e).name)
                        .join(", ")}
                  </p>
                </div>
              )}
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView3} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {this.state.program.program_category}
                </p>
              </div>

              {!this.state.program.unlimited_spots && (
                <div
                  className="box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px"
                  }}
                >
                  <img src={LiveView5} alt="icon" className="icon-img" />

                  <p className="icon-text">
                    Total Spots:{" "}
                    {this.state.program.total_spots
                      ? `${this.state.program.total_spots}`
                      : "# Spots Pending"}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="box-size">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h3 className="sub-title">Description</h3>
              {this.state.showDescription ? (
                <i
                  className="fas fa-chevron-up"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({
                      showDescription: !this.state.showDescription
                    })
                  }
                ></i>
              ) : (
                <i
                  className="fas fa-chevron-down"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({
                      showDescription: !this.state.showDescription
                    })
                  }
                ></i>
              )}
            </div>
            {this.state.showDescription && (
              <p className="box-description-text">
                {this.state.program.description}
              </p>
            )}
          </div>
          {this.state.program.additional_notes && (
            <div className="box-size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <h3 className="sub-title">Additional Details</h3>
                {this.state.showAdditionalDetails ? (
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showAdditionalDetails: !this.state.showAdditionalDetails
                      })
                    }
                  ></i>
                ) : (
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showAdditionalDetails: !this.state.showAdditionalDetails
                      })
                    }
                  ></i>
                )}
              </div>
              {this.state.showAdditionalDetails && (
                <p
                  // style={{
                  //   fontWeight: "400",
                  //   fontFamily: `"Open sans", sans-serif`,
                  //   whiteSpace: "pre-line",
                  //   marginBottom: "20px"
                  // }}
                  className="box-description-text"
                >
                  {this.state.program.additional_notes}
                </p>
              )}
            </div>
          )}

          {this.state.program.show_cancellation_policy && (
            <div className="box-size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <h3 className="sub-title">Cancellation Policy</h3>
                {this.state.showCancellationPolicy ? (
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showCancellationPolicy:
                          !this.state.showCancellationPolicy
                      })
                    }
                  ></i>
                ) : (
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showCancellationPolicy:
                          !this.state.showCancellationPolicy
                      })
                    }
                  ></i>
                )}
              </div>
              {this.state.showCancellationPolicy && (
                <p className="box-description-text">
                  {this.state.program.cancellation_policy}
                </p>
              )}
            </div>
          )}
        </div>

        <div className="rightcontainerprogram">
          <h2 className="right-cont-name">Booking Details</h2>

          <div style={{ width: "85%", margin: "0 auto" }}>
            {/* ================================== Display No Plans Available message ==================================*/}
            {availablePricePlans.length === 0 && (
              <div>
                <p>No plans available {noPlansAvailableMessage}</p>
              </div>
            )}
            {/* ================================== Display Price Cards for each plan ==================================*/}
            {availablePricePlans.map((e, i) => {
              const element = typeof e === "string" ? JSON.parse(e) : e;
              console.log("ELEMENT: ", element);
              if (
                (this.props.location.query &&
                  this.props.location.query.plan &&
                  this.props.location.query.visible) ||
                (this.props.location.search &&
                  this.props.location.search.includes("visible=true"))
              ) {
                if (parseInt(element.id) === this.state.selected) {
                  element.active = true;
                } else {
                  element.active = false;
                }
              }
              if (element.archived || element.active === false) {
                return null;
              }

              let planEnrollment = 0;
              this.state.customers.Enrollments.forEach(e => {
                if (
                  e.status_stage === "Accepted_Paid" &&
                  element.id === e.plan_id
                ) {
                  planEnrollment++;
                }
              });

              if (!element.type)
                return (
                  <div
                    className={
                      this.state.selected === parseInt(element.id)
                        ? this.props.classes.planSelected + " pricingcard"
                        : this.props.classes.plan + " pricingcard"
                    }
                    onClick={_ =>
                      this.setState({ selected: parseInt(element.id) })
                    }
                    key={i}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={this.state.selected === parseInt(element.id)}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: this.props.classes.radio,
                          checked: this.props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <p className="pricingcard-name">
                        Recurring ({element.billing_cycle})
                      </p>

                      <p className="pricingcard-days">
                        {/* eslint-disable-next-line eqeqeq*/}
                        {element.days_per_week}{" "}
                        {
                          /*currently a string but will be integer in future*/ element.days_per_week ==
                            "1"
                            ? "day"
                            : "days"
                        }{" "}
                        / week
                        {!element.isReplaceTimeEnabled ? (
                          <span style={{ fontStyle: "italic" }}>
                            {" ("}
                            {element.timing[0]
                              .replace(/\s/g, "")
                              .toLowerCase()}{" "}
                            -{" "}
                            {element.timing[1].replace(/\s/g, "").toLowerCase()}
                            )
                          </span>
                        ) : (
                          <span style={{ fontStyle: "italic" }}>
                            {" ("}
                            {element.description}
                            {")"}
                          </span>
                        )}
                      </p>
                    </div>
                    <div style={{ width: "60px" }}>
                      <p className="pricingcard-amount">
                        ${element.tuition_rate} / {element.billing_cycle}
                      </p>
                    </div>
                  </div>
                );
              if (element.type === "Single Sessions") {
                if (!this.state.singleSessionQuantity[i]) {
                  this.state.singleSessionQuantity.push(1);
                }
                return (
                  <div
                    className={
                      this.state.selected === parseInt(element.id)
                        ? this.props.classes.planSelected + " pricingcard"
                        : this.props.classes.plan + " pricingcard"
                    }
                    onClick={_ =>
                      this.setState({ selected: parseInt(element.id) })
                    }
                    key={i}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={this.state.selected === parseInt(element.id)}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: this.props.classes.radio,
                          checked: this.props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p className="pricingcard-name">
                        Single (
                        {element.duration_type === "Class"
                          ? "Classes"
                          : element.duration_type}
                        {element.duration ? ` ${element.duration}mins` : ""}) x{" "}
                        {this.state.singleSessionQuantity[i]}
                      </p>

                      <p
                        className="pricingcard-days"
                        style={{ textAlign: "left" }}
                      >
                        {isProgramTypeZero
                          ? `${formattedSingleDate} - ${formattedSingleDate}`
                          : this.state.program.number_of_weeks !== 0 &&
                            this.state.program.number_of_weeks !== -1
                            ? `${formattedSemesterStartDate} - ${formattedSemesterEndDate}`
                            : ""}
                        {element.description && (
                          <span style={{ fontStyle: "italic" }}>
                            {" ("}
                            {element.description}
                            {")"}
                          </span>
                        )}
                      </p>
                    </div>
                    <div style={{ width: "72px" }}>
                      <p className="pricingcard-amount">
                        ${element.total_price}{" "}
                        {element.frequency === "minutes"
                          ? ""
                          : `/ ${element.frequency}`}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#36b9cc",
                            marginLeft: "22px"
                          }}
                        >
                          <i
                            className="fas fa-minus-circle"
                            style={{ fontSize: "13px" }}
                            onClick={_ => {
                              if (this.state.singleSessionQuantity[i] === 1)
                                return;
                              this.state.singleSessionQuantity[i] -= 1;
                              this.setState(prevState => {
                                let singleSessionQuantity = [
                                  ...prevState.singleSessionQuantity
                                ];
                                return { singleSessionQuantity };
                              });
                            }}
                          ></i>
                          <i
                            className="fas fa-plus-circle"
                            style={{ fontSize: "13px" }}
                            onClick={_ => {
                              if (
                                this.state.singleSessionQuantity[i] ===
                                parseInt(element.quantity)
                              )
                                return;
                              this.state.singleSessionQuantity[i] += 1;
                              this.setState(prevState => {
                                let singleSessionQuantity = [
                                  ...prevState.singleSessionQuantity
                                ];
                                return { singleSessionQuantity };
                              });
                            }}
                          ></i>
                        </div>
                      </p>
                    </div>
                  </div>
                );
              }
              return (
                <>
                  {(!onlyShowOnePlanEvenIfInactive || (onlyShowOnePlanEvenIfInactive && !this.props.location.query?.modifier)) && (
                    <div
                      className={
                        this.state.selected === parseInt(element.id) &&
                          !this.state.dropIns &&
                          !this.state.installments
                          ? this.props.classes.planSelected + " pricingcard"
                          : this.props.classes.plan + " pricingcard"
                      }
                      onClick={_ => {
                        this.setState({
                          selected: parseInt(element.id),
                          dropIns: false,
                          installments: false
                        });
                        if (
                          element.planCap &&
                          element.planCap !== "" &&
                          parseInt(element.planCap) <= planEnrollment
                        ) {
                          this.setState({ isPlanFull: true });
                        } else {
                          this.setState({ isPlanFull: false });
                        }
                      }}
                      key={i}
                    >
                      <div className="radio-btn-positioning-container">
                        <Radio
                          checked={
                            this.state.selected === parseInt(element.id) &&
                            !this.state.dropIns &&
                            !this.state.installments
                          }
                          // onChange={handleChange}
                          value="d"
                          color="default"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "D" }}
                          size="large"
                          classes={{
                            root: this.props.classes.radio,
                            checked: this.props.classes.checkedRadio
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <p
                          // style={{
                          //   textAlign: "justify",
                          //   marginBottom: 0,
                          //   fontWeight: "bold",
                          //   color: "#505050",
                          //   fontFamily: `Montserrat,sans-serif`,
                          //   fontSize: "1.2em"
                          // }}
                          className="pricingcard-name"
                        >
                          {element.description &&
                            element.description !== null &&
                            (element.type === "Semester" ||
                              element.type === "One-Time" ||
                              element.type === "Free") ? (
                            <span style={{ fontStyle: "italic" }}>
                              {element.description}
                            </span>
                          ) : (
                            `Online (${element.type})`
                          )}
                        </p>
                        <p
                          // style={{
                          //   textAlign: "justify",
                          //   color: "gray",
                          //   marginBottom: 0,
                          //   fontFamily: `Open Sans,sans-serif`,
                          //   fontSize: "0.9em"
                          // }}
                          className="pricingcard-days"
                        >
                          {isProgramTypeZero
                            ? formattedSingleDate
                            : formattedSemesterStartDate}{" "}
                          -{" "}
                          {isProgramTypeZero
                            ? formattedSingleDate
                            : formattedSemesterEndDate}
                        </p>
                        <span
                          style={{ fontWeight: "normal", fontSize: "12px" }}
                        ></span>
                        <p className="pricingcard-days">
                          {element.planCap && element.planCap != null
                            ? parseInt(element.planCap) <= planEnrollment
                              ? `Spots ${element.planCap} (FULL)`
                              : `Spots ${element.planCap}`
                            : ""}
                        </p>
                      </div>

                      <p
                        // style={{
                        //   color: "#75b2e0",
                        //   marginBottom: 0,
                        //   fontWeight: "bold",
                        //   fontSize: "1.3em"
                        // }}
                        className="pricingcard-amount"
                      >
                        {element.total_price > 0
                          ? `$${element.total_price}`
                          : "Free"}
                      </p>
                    </div>
                  )}
                  {(element.installments && (!onlyShowOnePlanEvenIfInactive || (onlyShowOnePlanEvenIfInactive &&
                    (this.props.location.query?.modifier === "Installments" || this.props.location.query?.modifier === "I")))) && (
                      <div
                        className={
                          this.state.selected === parseInt(element.id) &&
                            this.state.installments
                            ? this.props.classes.planSelected + " pricingcard"
                            : this.props.classes.plan + " pricingcard"
                        }
                        onClick={_ =>
                          this.setState({
                            selected: parseInt(element.id),
                            dropIns: false,
                            installments: true
                          })
                        }
                        key={i}
                      >
                        <div className="radio-btn-positioning-container">
                          <Radio
                            checked={
                              this.state.selected === parseInt(element.id) &&
                              this.state.installments
                            }
                            // onChange={handleChange}
                            value="d"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "D" }}
                            size="large"
                            classes={{
                              root: this.props.classes.radio,
                              checked: this.props.classes.checkedRadio
                            }}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <p
                            // style={{
                            //   textAlign: "justify",
                            //   marginBottom: 0,
                            //   fontWeight: "bold",
                            //   color: "#505050",
                            //   fontFamily: `Montserrat,sans-serif`,
                            //   fontSize: "1.2em"
                            // }}
                            className="pricingcard-name"
                          >
                            {element.description &&
                              element.description !== null ? (
                              <span style={{ fontStyle: "italic" }}>
                                {element.description}
                              </span>
                            ) : (
                              `Online (${element.type})`
                            )} - Inst
                          </p>
                          <p
                            // style={{
                            //   textAlign: "justify",
                            //   color: "gray",
                            //   marginBottom: 0,
                            //   fontFamily: `Open Sans,sans-serif`,
                            //   fontSize: "0.9em"
                            // }}
                            className="pricingcard-days"
                          >
                            {format(
                              new Date(this.state.program.program_start),
                              "LL/dd/yy"
                            )}{" "}
                            -{" "}
                            {isProgramTypeZero
                              ? format(
                                new Date(
                                  this.state.program.program_start + "T00:00"
                                ),
                                "LL/dd/yy"
                              )
                              : format(
                                addWeeks(
                                  new Date(this.state.program.program_start),
                                  this.state.program.number_of_weeks
                                ),
                                "LL/dd/yy"
                              )}
                          </p>
                          <span
                            style={{ fontWeight: "normal", fontSize: "12px" }}
                          ></span>
                        </div>

                        <p
                          // style={{
                          //   color: "#75b2e0",
                          //   marginBottom: 0,
                          //   fontWeight: "bold",
                          //   fontSize: "1.3em"
                          // }}
                          className="pricingcard-amount"
                        >
                          $
                          {parseFloat(
                            (parseFloat(element.total_price) /
                              parseInt(element.installments_breakdown)) *
                            (1 + parseInt(element.installments_interest ? element.installments_interest : 0) / 100)
                          ).toFixed(2)}{" "}
                          / {element.installments_plan}
                        </p>
                      </div>
                    )}
                  {element.dropIns && (
                    <div
                      className={
                        this.state.selected === parseInt(element.id) &&
                          this.state.dropIns
                          ? this.props.classes.planSelected + " pricingcard"
                          : this.props.classes.plan + " pricingcard"
                      }
                      onClick={_ =>
                        this.setState({
                          selected: parseInt(element.id),
                          dropIns: true,
                          installments: false
                        })
                      }
                      key={i}
                    >
                      <div className="radio-btn-positioning-container">
                        <Radio
                          checked={
                            this.state.selected === parseInt(element.id) &&
                            this.state.dropIns
                          }
                          // onChange={handleChange}
                          value="d"
                          color="default"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "D" }}
                          size="large"
                          classes={{
                            root: this.props.classes.radio,
                            checked: this.props.classes.checkedRadio
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <p
                          // style={{
                          //   textAlign: "justify",
                          //   marginBottom: 0,
                          //   fontWeight: "bold",
                          //   color: "#505050",
                          //   fontFamily: `Montserrat,sans-serif`,
                          //   fontSize: "1.2em"
                          // }}
                          className="pricingcard-name"
                        >
                          {element.description &&
                            element.description !== null ? (
                            <span style={{ fontStyle: "italic" }}>
                              {element.description} - Drop-in
                            </span>
                          ) : (
                            `Online (Semester - Drop-in)`
                          )}

                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            color: "gray",
                            marginBottom: 0,
                            fontFamily: `Open Sans,sans-serif`,
                            fontSize: "0.9em"
                          }}
                        >
                          {format(
                            new Date(this.state.program.program_start),
                            "LL/dd/yy"
                          )}{" "}
                          -{" "}
                          {isProgramTypeZero
                            ? format(
                              new Date(
                                this.state.program.program_start + "T00:00"
                              ),
                              "LL/dd/yy"
                            )
                            : format(
                              addWeeks(
                                new Date(this.state.program.program_start),
                                this.state.program.number_of_weeks
                              ),
                              "LL/dd/yy"
                            )}
                        </p>
                        <span
                          style={{ fontWeight: "normal", fontSize: "12px" }}
                        ></span>
                      </div>

                      <p
                        // style={{
                        //   color: "#75b2e0",
                        //   marginBottom: 0,
                        //   fontWeight: "bold",
                        //   fontSize: "1.3em"
                        // }}
                        className="pricingcard-amount"
                      >
                        $
                        {parseFloat(
                          (element.total_price / element.dayCount) *
                          (1 + parseInt(element.dropin_interest) / 100)
                        ).toFixed(0)}
                      </p>
                    </div>
                  )}
                </>
              );
            })}

            {this.state.selected !== null &&
              !isNaN(this.state.selected) &&
              availablePricePlans.length > 0 && (
                <SummarySubTotal
                  /* plans={this.state.program.plans} */
                  /* selected={this.state.selected} */
                  plans={availablePricePlans}
                  selectedPlanId={
                    availablePricePlans.length > 0 ? this.state.selected : null
                  }
                  quantity={
                    this.state.singleSessionQuantity[this.state.selected]
                  }
                />
              )}

            {/* ================================== Display button for Checkout ==================================*/}
            {((this.state.program.isCartDisabled ||
              this.state.selectedPlan?.addOn) &&
              availablePricePlans.length > 0) ||
              this.state.isEnableWaitlist ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor:
                      this.state.selected === null ||
                        this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist)
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    this.state.selected === null ||
                    this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)
                  }
                  onClick={onEnrollNow}
                >
                  Enroll Here Now
                </button>
              </div>
            ) : (
              availablePricePlans.length > 0 &&
              (this.state.installments ||
                this.state.program.auto_withdraw ||
                this.state.program.isRecurring === true ? (
                <>
                  <button
                    style={{
                      width: `70%`,
                      padding: `10px`,
                      backgroundColor: `rgb(52, 63, 100)`,
                      color: `white`,
                      fontWeight: `bold`,
                      border: 0,
                      marginTop: "20px",
                      cursor:
                        this.state.isBookingFull || this.state.isPlanFull
                          ? "not-allowed"
                          : "pointer"
                    }}
                    disabled={this.state.isBookingFull || this.state.isPlanFull}
                    onClick={onEnrollNow}
                  >
                    Enroll Here Now
                  </button>
                  <div>
                    <i className="bi bi-info-circle" /> Recurring programs must
                    be checked out individually.
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <button
                    style={{
                      width: `70%`,
                      padding: `10px`,
                      backgroundColor: `rgb(52, 63, 100)`,
                      color: `white`,
                      fontWeight: `bold`,
                      marginTop: "40px",
                      border: 0,
                      cursor:
                        this.state.disableAddToCart ||
                          this.state.selected === null ||
                          this.state.isBookingFull ||
                          (this.state.planWaitlist &&
                            !this.state.isProgramWaitlist)
                          ? "not-allowed"
                          : "pointer"
                    }}
                    disabled={
                      this.state.disableAddToCart ||
                      this.state.selected === null ||
                      this.state.isBookingFull ||
                      (this.state.planWaitlist && !this.state.isProgramWaitlist)
                    }
                    onClick={onAddToCart}
                  >
                    Add to Cart
                  </button>
                  <span>-------- OR --------</span>
                  <button
                    style={{
                      width: `70%`,
                      padding: `10px`,
                      backgroundColor: `rgb(211,52,49)`,
                      color: `white`,
                      fontWeight: `bold`,
                      border: 0,
                      marginTop: "20px",
                      cursor:
                        this.state.isBookingFull || this.state.isPlanFull
                          ? "not-allowed"
                          : "pointer"
                    }}
                    disabled={this.state.isBookingFull || this.state.isPlanFull}
                    onClick={onEnrollNow}
                  >
                    Enroll Here Now
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(SpecificOnline);
