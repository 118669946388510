import React from "react";
import PropTypes from "prop-types";

import { format, addDays, addMonths } from "date-fns";
import { getSelectedProgramPlan, coupon_discount } from "./utils";

// What is 'i'?
const daystoAdd = (startDate) => {
  return {
    Monthly: i => addMonths(new Date(startDate), 1 * i),
    "Bi-Weekly": i => addDays(new Date(startDate), 14 * i),
    Weekly: i => addDays(new Date(startDate), 7 * i)
  };
};

const ProgramInstallmentsFuturePayments = props => {
  console.log(props);
  const parsedPlan = getSelectedProgramPlan(props.program, props.selected);
  const taxRate = parsedPlan.isTaxEnabled
    ? parsedPlan.taxInfo[0].percentage / 100
    : 0;
  const installments_interest = parseInt(parsedPlan.installments_interest ? parsedPlan.installments_interest : 0);
  let total_price = parseFloat(parsedPlan.total_price);
  console.log("TOTAL PRICE 2", total_price);
  if (parsedPlan.addOnItems) {
    parsedPlan.addOnItems.map(item => {
      total_price += parseFloat(item.value);
    });
  }
  console.log("total price again: ", total_price);
  const customDates = [
    parsedPlan.custom_date1,
    parsedPlan.custom_date2,
    parsedPlan.custom_date3,
    parsedPlan.custom_date4,
    parsedPlan.custom_date5,
    parsedPlan.custom_date6
  ];
  console.log(parsedPlan.installments_plan);

  let coupon_amnt = 0;
  console.log(installments_interest);
  total_price =
    (total_price * (1 + installments_interest / 100))
  console.log("here price: ", total_price);
  if (props.coupon) {
    coupon_amnt = coupon_discount(props.coupon, parseFloat(total_price));
  }
  
  total_price =
    (total_price - coupon_amnt) *
    parseFloat(1 + taxRate);
  const amnt =
    (parseFloat(total_price) - parseFloat(parsedPlan.deposit)) /
    parseInt(parsedPlan.installments_breakdown);
  // If installment includes a deposit, change increment to 0.
  let increment = 1;
  if (
    parsedPlan.includeDeposit &&
    parsedPlan.deposit &&
    parsedPlan.deposit !== "0"
  ) {
    increment = 0;
  }
  if (parsedPlan.installments_plan === "Custom") {
    return (
      <>
        {/* <h4
          style={{
            padding: "15px 10px",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 600
          }}
        >
          Future Payments (Tax included, if any)
        </h4> */}

        {new Array(parseInt(parsedPlan.installments_breakdown) - increment)
          .fill(1)
          .map((_, i) => (
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 10px",
                borderBottom:
                  i + 1 <
                  parseInt(parsedPlan.installments_breakdown) - increment
                    ? "1px solid #dedede"
                    : "none"
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: 500
                }}
              >
                Installment #{i + 1 + increment} -{" "}
                {"Payable On " +
                  format(new Date(customDates[i + increment]), "LL/dd/yy")}
              </p>
              <span style={{ fontWeight: "500" }}>
                ${parseFloat(amnt).toFixed(2)}
              </span>
            </li>
          ))}
      </>
    );
  } else {
    return (
      <>
        {/* <h4
          style={{
            padding: "15px 10px",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 600
          }}
        >
          Future Payments (Tax included, if any)
        </h4> */}

        {new Array(parseInt(parsedPlan.installments_breakdown) - increment)
          .fill(1)
          .map((_, i) => (
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 10px",
                borderBottom: "1px solid #dedede"
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: 500
                }}
              >
                Installment #{i + 1 + increment} -{" "}
                {"Payable On " +
                  format(
                    daystoAdd(props.program.program_start || props.program.startDate || parsedPlan.startDate)[parsedPlan.installments_plan](i + 1),
                    "LL/dd/yy"
                  )}
              </p>
              <span style={{ fontWeight: "500" }}>
                ${parseFloat(amnt).toFixed(2)}
              </span>
            </li>
          ))}
        <li
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 10px",
            borderBottom: "1px solid #dedede"
          }}
        >
          <p
            style={{
              marginBottom: 0,
              fontFamily: "Open Sans, sans-serif",
              fontWeight: 700
            }}
          >
            Total <span style={{ fontStyle: "italic" }}>(Today)</span>
          </p>
          <span style={{ fontWeight: "700" }}>
            $
            {parseFloat(parsedPlan.deposit) !== 0
              ? parseFloat(parsedPlan.deposit).toFixed(2)
              : parseFloat(amnt).toFixed(2)}
          </span>
        </li>
      </>
    );
  }
};

ProgramInstallmentsFuturePayments.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramInstallmentsFuturePayments;
