import React from "reactn";
import {
  MemberShip1,
  MemberShip2,
  LiveView2,
  LiveView3,
  OrangePin
} from "../../assets";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import Checkout from "./checkout";
import SignIn from "../../components/modals/SignInModal";
import CartModal from "../../components/parents/CartModal";
import { withStyles } from "@material-ui/core/styles";
import "../../assets/css/componentSpecificCss/liveSpecificPages.css";
import { toast } from "react-toastify";
import SignUpNewModal from "../../components/modals/SignUpNewModal";
import Radio from "@material-ui/core/Radio";
import SummarySubTotalMembership from "./SpecificPageUtils/SummarySubTotalMembership";
import NoDataAvailable from "../../components/front/partner/NoDataAvailable";

import ShareButtonModal from "../../components/modals/ShareButtonModal";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

class SpecificMembership extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      textShown: false,
      mapShown: false,
      membership: { membership_type: [], prices: [] },
      selected: null,
      checkout: false,
      orgSlug: "",
      email: "",
      isEnableWaitlist: false,
      isBookingFull: false,
      checkoutType: "Regular",
      showCart: false,
      disableAddToCart: false
    };
  }
  async componentDidMount() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/search/membership/${this.props.match.params.id}`;
    const results = await axios.get(ep);
    console.log("results", results);

    if (!results.data.success) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }
    this.setState({ orgSlug: results.data.data.slug });
    if (results.data.success && results.data.data.isArchived) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }

    const isCountLargerThanSpots = !!(
      results.data.data.max_amount &&
      (await this.fetchEnrollmentCount(results.data.data.id)) >=
      results.data.data.max_amount
    );

    // let alreadyEnrolled = false;
    // if (this.global.dsUser) {
    //   const allEnrollments = await this.fetchUserEnrollments();
    //   alreadyEnrolled = allEnrollments.filter(user =>
    //     user.dsUserId === this.global.dsUser.id &&
    //     user.membershipId === results.data.data.id &&
    //     user.status_stage !== "Cancelled"
    //   ).length > 0;
    // }

    const isEnableWaitlist = !!(
      results.data.data.enable_waitlist && isCountLargerThanSpots
    );

    const isBookingFull = isCountLargerThanSpots && !isEnableWaitlist;

    if (this.props.location.query && this.props.location.query.plan) {
      this.setState({
        selected: parseInt(this.props.location.query.plan)
      });
    }

    if (this.props.location.query && this.props.location.query.visible && this.props.location.query.visible === "true") {
      this.setGlobal({
        footer: false
      })
    }

    // if (
    //   this.props.location.search &&
    //   this.props.location.search.includes("visible=true")
    // ) {
    //   const planNumber = parseInt(
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   console.log(
    //     "plan number",
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   this.setState({ selected: planNumber });
    // }

    this.setState({
      membership: results.data.data,
      isEnableWaitlist: isEnableWaitlist,
      isBookingFull: isBookingFull
    });
    this.setGlobal({ loading: false });
  }

  fetchEnrollmentCount = async membershipId => {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/enrollments/count/${membershipId}`;
    const result = await axios.get(ep);

    // this.setState({
    // enrollmentCount: parseInt(result.data.data.count)
    // });

    return parseInt(result.data.data.count);
  };

  fetchUserEnrollments = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/par/customers/all/${this.global.dsUser.id}`;
    const result = await axios.get(ep);
    return result.data.data;
  }

  render() {
    //=================================================================================================
    const failedLocalStorageDsUserToken = () => {
      try {
        if (localStorage.getItem("ds_token")) {
          return false;
        }
        return false;
      } catch {
        console.log("FAILED TO FETCH FROM LOCAL STORAGE");
        return true;
      }
    };

    const isPlanSelected = () =>
      this.state.selected !== null && this.state.selected !== undefined; //do not use -1 to represent a non-selection. And id=0 is a valid price plan!

    const getAccountType = user => user.accountType;
    const isUserParent = user => getAccountType(user) === "Parent";
    const isUserVolunteer = user => getAccountType(user) === "Volunteer";
    const isUserStudent = user => getAccountType(user) === "Student";
    const canUserVisitCheckout = user =>
      isUserParent(user) || isUserVolunteer(user) || isUserStudent(user);
    const checkUserAndLoggedInAccountType = () =>
      this.global.dsUser && canUserVisitCheckout(this.global.dsUser);

    //===========================================================================
    // click handler for Enroll Now button
    //===========================================================================
    const onEnrollNow = async () => {
      if (!isPlanSelected()) {
        return toast.error(`Please select a plan!`);
      }
      const planId = this.state.selected;

      if (failedLocalStorageDsUserToken()) {
        console.log(
          "onEnrollNow: Failed to access local storage. Invoking window.open()."
        );
        window.open(
          `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/membership/${this.state.membership.id}?plan=${planId}`
        );
        return;
      }

      this.setState({ checkoutType: "Regular" });

      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
        if (checkUserAndLoggedInAccountType()) {
          return this.setState({ checkout: true });
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
      if (!localStorage.getItem("ds_token")) {
        return this.setState({ isOpenSignUpModal: true });
      } else {
        if (checkUserAndLoggedInAccountType()) {
          return this.setState({ checkout: true });
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
    };

    //===========================================================================
    // click handler for Add to Cart button
    //===========================================================================
    const onAddToCart = async () => {
      if (!isPlanSelected()) {
        return toast.error(`Please select a plan!`);
      }
      const planId = this.state.selected;

      if (failedLocalStorageDsUserToken()) {
        console.log(
          "onAddToCart: Failed to access local storage. Invoking window.open()."
        );
        window.open(
          `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/membership/${this.state.membership.id}?plan=${planId}`
        );
        return;
      }

      this.setState({ checkoutType: "Cart" });

      const data = {
        partnerId: this.state.membership.partnerId,
        productId: this.state.membership.id,
        planId: planId,
        quantity: 1,
        productTable: "partner_memberships"
      };

      const addToCart = async () => {
        await this.dispatch.getCart({
          partnerSlug: this.props.match.params.slug
        });
        const ep = `${process.env.REACT_APP_API}/par/cart`;
        const res = await axios.put(ep, data);
        if (res.data.success) {
          toast.success("Added to cart!");
          this.setState({ showCart: true });
          console.log("this.global.cart:", this.global.cart);
        } else {
          toast.error(res.data.message);
        }
      };

      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
        if (checkUserAndLoggedInAccountType()) {
          await addToCart();
          return;
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
      if (!localStorage.getItem("ds_token")) {
        return this.setState({ isOpenSignUpModal: true });
      } else {
        if (checkUserAndLoggedInAccountType()) {
          await addToCart();
          return;
        }
        this.dispatch.logout();
        return this.setState({ isOpenSignUpModal: true });
      }
    };
    //=================================================================================================

    if (!this.state.membership["main_lat"]) {
      return null;
    }

    console.log("slugs are", this.state.orgSlug, this.props.match.params.slug);
    if (this.state.orgSlug !== this.props.match.params.slug) {
      return null;
    }

    console.log(
      "failedLocalStorageDsUserToken",
      failedLocalStorageDsUserToken()
    );

    if (this.state.membership?.published === false && (!this.props.location.query?.email || !this.props.location.query?.visible === "true")) {
      return <NoDataAvailable message="Membership Unavailable" />
    }

    return this.state.checkout ? (
      <Checkout
        form={this.state.membership.formId}
        program={this.state.membership}
        organization={this.state.membership.organization_title}
        selected={this.state.selected}
        close={_ => this.setState({ checkout: false })}
        type={"Membership"}
        planWaitlist={this.state.isEnableWaitlist}
      />
    ) : (
      <div className="containerall" style={{ overflowX: "hidden" }}>
        {this.state.showCart && (
          <CartModal
            partnerSlug={this.props.match.params.slug}
            animation={true}
            hideCart={() => {
              this.setState({ showCart: false });
            }}
            toggleAddToCart={() => {
              this.setState({ disableAddToCart: !this.state.disableAddToCart });
            }}
          />
        )}
        {this.state.signIn && (
          <SignIn
            onClose={_ => this.setState({ signIn: false, email: "" })}
            program={this.state.membership}
            checkout={_ => {
              if (this.state.checkoutType === "Cart") {
                this.setState({ signIn: false });
                onAddToCart();
              } else {
                this.setState({ signIn: false, checkout: true });
              }
            }}
            email={this.state.email}
          />
        )}
        {this.state.isOpenSignUpModal && (
          <SignUpNewModal
            onClose={_ => this.setState({ isOpenSignUpModal: false })}
            program={this.state.membership}
            checkout={_ => {
              if (this.state.checkoutType === "Cart") {
                this.setState({ isOpenSignUpModal: false });
                onAddToCart();
              } else {
                this.setState({ isOpenSignUpModal: false, checkout: true });
              }
            }}
            openSignInModal={_ => this.setState({ signIn: true })}
            setEmail={enteredEmail => {
              this.setState({ email: enteredEmail });
            }}
          />
        )}
        {this.state.isOpenShareButtonModal && (
          <ShareButtonModal
            onClose={() => {
              this.setState({
                ...this.state,
                isOpenShareButtonModal: false
              })
              console.log("HERE", this.props.history.location.pathname)
              console.log((process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
                + this.props.history.location.pathname)
            }}
            url={(process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
              + this.props.history.location.pathname}
            // quote={"HELLO"}
            hashtag={"#DreamSchools"}
          // description={"DESCRIPTION STUFF"}
          />
        )}
        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">
              {this.state.membership.membership_name}{" "}
              {this.state.isEnableWaitlist && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    On Waitlist
                  </span>
                  {")"}
                </span>
              )}
              {this.state.isBookingFull && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    FULL
                  </span>
                  {")"}
                </span>
              )}
              {/* {(this.state.alreadyEnrolled && !this.state.membership.allow_same_enrollment &&
                    !this.state.membership.application_required) && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    Already Enrolled
                  </span>
                  {")"}
                </span>
              )} */}
            </h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                className="org-title"
                onClick={_ => {
                  this.props.location.pathname.includes("iframe")
                    ? this.props.history.push(
                      `/iframes/programs/${this.props.match.params.slug}`
                    )
                    : this.props.history.push(
                      `/programs/${this.props.match.params.slug}`
                    );
                }}
              >
                {this.state.membership.organization_title}
              </h4>
              <button
                className="partnerbutton"
                style={{ borderRadius: "10px", marginRight: "10px" }}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    isOpenShareButtonModal: true,
                  })
                }}
              >
                Share
              </button>
            </div>
          </div>

          <div className="maincontainerdiv">
            <img
              className="imageprogram"
              alt="member"
              src={
                this.state.membership.membership_image === 2
                  ? `${process.env.REACT_APP_DS_FILES_S3}/${this.state.membership.membership_image_url}`
                  : this.state.membership.membership_image === 0
                    ? MemberShip1
                    : MemberShip2
              }
            />

            <div
              className="widgetdata"
              style={{
                textAlign: "center",
                marginLeft: "50px"
              }}
            >
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView2} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {this.state.membership.membership_type.map((e, i) => {
                    const element = JSON.parse(e);
                    return i + 1 ===
                      this.state.membership.membership_type.length ? (
                      element.name
                    ) : (
                      <p>{element.name + ","}</p>
                    );
                  })}
                </p>
              </div>
              {this.state.membership.show_max_amount && (
                <div
                  className="box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px"
                  }}
                >
                  <img src={LiveView3} alt="icon" className="icon-img" />

                  <p className="icon-text">
                    Total Memberships Available:{" "}
                    {this.state.membership.max_amount}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Membership Description</h3>
            <p
              // style={{
              //   fontWeight: "400",
              //   fontFamily: `"Open sans", sans-serif`
              // }}
              className="box-description-text"
            >
              {this.state.membership.description}
            </p>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Additional Perks</h3>
            <p
              // style={{
              //   fontWeight: "400",
              //   fontFamily: `"Open sans", sans-serif`
              // }}
              className="box-description-text"
            >
              {this.state.membership.additional_perks}
            </p>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Cancellation and Refunds</h3>
            <p
              // style={{
              //   fontWeight: "400",
              //   fontFamily: `"Open sans", sans-serif`
              // }}
              className="box-description-text"
            >
              {/*TODO*/}
            </p>
          </div>

          <div className="box-size">
            <h3 className="sub-title">Location</h3>
            <div className="map-size">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBX7aSRy1hovgaRvgemOy0Wl7aizjTJ1QE"
                }}
                defaultZoom={9}
                defaultCenter={{
                  lat: parseFloat(this.state.membership.main_lat),
                  lng: parseFloat(this.state.membership.main_long)
                }}
                options={{ gestureHandling: "greedy" }}
              >
                <div
                  lat={this.state.membership.main_lat}
                  lng={this.state.membership.main_long}
                  style={{
                    backgroundImage: `url(${OrangePin})`,
                    backgroundSize: "cover",
                    height: 30,
                    width: 22,
                    cursor: "pointer",
                    zIndex: 10,
                    position: "relative",
                    bottom: 15,
                    right: 15
                  }}
                ></div>
              </GoogleMapReact>
            </div>
          </div>
        </div>

        <div className="rightcontainerprogram">
          <h2 className="right-cont-name">Membership Plans</h2>

          <div style={{ width: "80%", margin: "0 auto" }}>
            {this.state.membership.prices.map((ele, key) => {
              const e = typeof ele === "string" ? JSON.parse(ele) : ele;
              if (
                (this.props.location.query &&
                  this.props.location.query.plan &&
                  this.props.location.query.visible) ||
                (this.props.location.search &&
                  this.props.location.search.includes("plan=") &&
                  this.props.location.search.includes("visible=true"))
              ) {
                if (parseInt(e.id) === this.state.selected) {
                  e.active = true;
                } else {
                  e.active = false;
                }
              }
              if (e.archived || e.active === false) {
                return null;
              }
              return (
                <div
                  className={
                    this.state.selected === key
                      ? this.props.classes.planSelected + " pricingcard"
                      : this.props.classes.plan + " pricingcard"
                  }
                  onClick={_ => this.setState({ selected: key })}
                  key={key}
                >
                  <div className="radio-btn-positioning-container">
                    <Radio
                      checked={this.state.selected === key}
                      // onChange={handleChange}
                      value="d"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "D" }}
                      size="large"
                      classes={{
                        root: this.props.classes.radio,
                        checked: this.props.classes.checkedRadio
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <p className="pricingcard-name">
                      {e.membership_term} Month Term <br />{" "}
                    </p>

                    <p className="pricingcard-days">
                      {e.auto_renew
                        ? "Subscription Membership"
                        : "Standard Membership"}
                    </p>
                  </div>
                  <div style={{ width: "50px" }}>
                    <p className="pricingcard-amount">${e.price_per_term}</p>
                  </div>
                </div>
              );
            })}

            {this.state.selected !== null && (
              <SummarySubTotalMembership
                plans={this.state.membership.prices}
                selected={this.state.selected}
              />
            )}
            {this.state.membership.isCartDisabled ||
              this.state.isEnableWaitlist ? (
              <>
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    fontWeight: `bold`,
                    border: 0,
                    marginTop: "40px",
                    cursor:
                      this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist) ? "not-allowed" : "pointer"
                  }}
                  disabled={this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)}
                  onClick={onEnrollNow}
                >
                  Register Here Now
                </button>
              </>
            ) : JSON.parse(
              this.state.membership.prices.filter(
                e => JSON.parse(e).id === this.state.selected
              )[0] || null
            )?.auto_renew ? (
              <>
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    fontWeight: `bold`,
                    border: 0,
                    marginTop: "40px",
                    cursor:
                      this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist) ? "not-allowed" : "pointer"
                  }}
                  disabled={
                    this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)}
                  onClick={onEnrollNow}
                >
                  Register Here Now
                </button>
                <div>
                  <i className="bi bi-info-circle" /> Recurring memberships must
                  be checked out individually.
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    fontWeight: `bold`,
                    border: 0,
                    marginTop: "40px",
                    cursor:
                      this.state.disableAddToCart || this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist)
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    this.state.disableAddToCart || this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)
                  }
                  onClick={onAddToCart}
                >
                  Add to Cart
                </button>
                <span>-------- OR --------</span>
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(211,52,49)`,
                    color: `white`,
                    marginTop: `10px`,
                    fontWeight: `bold`,
                    border: 0,
                    cursor:
                      this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist) ? "not-allowed" : "pointer"
                  }}
                  disabled={
                    this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)
                  }
                  onClick={onEnrollNow}
                >
                  Register Here Now
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(SpecificMembership);
