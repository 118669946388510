import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import { OrangePin } from "../../../assets";
const PartyDescription = ({ party }) => {
  const getLocation = locations => {
    if (!locations) {
      return;
    }
    const data = locations.filter(e => e.address === party.address1);
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={party.main_lat}
        lng={party.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  };
  return (
    <>
      <div className="box-size">
        <h3 className="sub-title">Party Description</h3>

        <p
          style={{
            fontWeight: "400",
            fontFamily: `"Open sans", sans-serif`,
            whiteSpace: "pre-line"
          }}
        >
          {party.description}
        </p>
      </div>
      <div className="box-size">
        <h3 className="sub-title">Location</h3>
        <div className="map-size">
          <p>Address : {party.address1}</p>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBX7aSRy1hovgaRvgemOy0Wl7aizjTJ1QE"
            }}
            defaultZoom={9}
            defaultCenter={{ lat: 43.695572, lng: -79.415605 }}
            options={{ gestureHandling: "greedy" }}
          >
            {getLocation(party.other_locations)}
          </GoogleMapReact>
        </div>
      </div>
    </>
  );
};

PartyDescription.propTypes = {
  party: PropTypes.object.isRequired
};

export default PartyDescription;
