import React from "react";
import "../fieldComponent.css";
import "./DatePicker.css";
import Title from "../Title";

import DatePicker from "react-datepicker";

const DatePickerField = props => {
  const {
    name,
    required,
    placeholder,
    value,
    handleChange,
    minDate,
    dateFormat,
    fieldValid,
    showTimeInput,
    setFieldValid,
    info,
    styles,
    wrapperStyles,
    titleStyle,
    iconStyles,
    dateFilter,
    selectedDates,
    disabledKeyboardNavigation,
    inline,
    openToDate
  } = props;

  console.log("GOT HERE", showTimeInput)
  return (
    <div style={wrapperStyles}>
      <Title name={name} required={required || false} info={info} titleStyle={titleStyle} iconStyles={iconStyles}/>
      <div style={styles}>
        <DatePicker
          selected={value || null}
          highlightDates={selectedDates}
          onChange={handleChange}
          filterDate={dateFilter}
          minDate={minDate}
          showYearDropdown
          showMonthDropdown
          showTimeInput={showTimeInput}
          dateFormat={dateFormat}
          className={`field-component-input-box ${
            !fieldValid ? "date-picker-invalid" : ""
          }`}
          placeholderText={placeholder}
          autoComplete="off"
          disabledKeyboardNavigation={disabledKeyboardNavigation}
          inline={inline}
          openToDate={openToDate}
        />
      </div>
    </div>
  );
};

export default DatePickerField;
